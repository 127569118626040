.page-container {
  display: flex;
  height: 100vh;
  align-items: center;
}

.container {
  max-width: 420px;
}

.content {
  padding: 3.8rem 2.6rem;
}

.form-group {
  margin-bottom: 2rem;
}

.form-group .alert {
  margin-top: 0.5rem;
}

.form-control {
  border: none;
  border-bottom: 1px solid #ccc;
  box-shadow: none;
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
}

.form-control:disabled {
  background-color: transparent;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  box-shadow: none;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}

.btn-custom {
  width: 100%;
  border-color: #0b1340;
  background-color: #0b1340;
  border-radius: 6px;
  color: #fff;
  box-shadow: none;
  outline: none;
}

.btn-custom:hover {
  border-color: #0b1340;
  background-color: #0b1340;
  color: #fff;
}

.btn-custom:active,
.btn-custom:focus {
  box-shadow: none;
  background-color: #0b1340;
}

.title-header {
  text-align: center;
  margin-bottom: 2rem;
}

.click {
  color: #1890ff;
  text-decoration: underline #1890ff;
}
