.detail {
  align-items: flex-start;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #115fa7;
    min-height: 120px;
    .business-label {
      color: #fff;
      font-size: 1rem;
    }
    .business-name {
      color: #fff;
      font-size: 1.25rem;
      min-height: 36px;
    }
  }
  .queue-detail {
    width: 100%;
    height: 100vh;
    padding: 0 0rem;
    overflow-y: scroll;
    .label-data {
      display: flex;
      justify-content: space-between;
      color: #303030;
      padding: 0.8rem 1rem;
      border-bottom: 1px solid #bdbdbd;
    }
    .line {
      border-bottom: solid #eee 2px;
    }
    .label-name {
      color: #000;
      margin-left: 1rem;
    }
    .label-unit {
      font-weight: normal;
      margin-left: 1rem;
    }
  }
  .project-daterange {
    font-size: 1rem;
    color: #ffffff;
  }
  .expense-total-data {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1rem;
    border-top: 1px solid #bdbdbd;
    font-weight: bold;
  }
}
