.dashboard {
  flex-direction: column;
  align-items: flex-start;

  .header {
    padding: 0.5rem 1rem;
    width: 100%;
    color: #fff;
    background-color: #115fa7;
  }

  .business-group-card {
    width: 100%;
    margin: 1rem 0;
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid #dddddd;
    user-select: none;
    -webkit-appearance: none;

    .business-group-name {
      flex: 1;
      font-size: 1.2rem;
      font-weight: bold;
      color: #1890ff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: underline #1890ff;
    }
  }

  .btn-more {
    font-size: 0.8rem;
    padding: 2px 8px;
    color: #fff;
    background-color: #115fa7;
    border-radius: 4px;
    min-width: fit-content;
    align-self: center;
  }

  .business-card {
    display: flex;
    width: 100%;
    padding-bottom: 0.6rem;
    background-color: #fff;
    color: #000;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    user-select: none;
    -webkit-appearance: none;

    .business-name-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      margin: 0 1.5rem 0 2rem;
      align-items: center;
      .business-name {
        flex: 1;
        color: #1890ff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: underline #1890ff;
      }
    }
  }

  .project-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 0.6rem;
    background-color: #fff;
    color: #000;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    user-select: none;
    -webkit-appearance: none;

    .project-name-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      align-items: center;
      .project-name {
        flex: 1;
        color: #1890ff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: underline #1890ff;
      }
    }

    .project-expense-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      padding: 0 1.5rem 0 2rem;
      align-items: center;
      .project-expense {
        flex: 1;
        color: #222222;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .project-daterange {
      font-size: 0.9rem;
      color: #757575;
    }
  }

  .revenue-report-error {
    color: #757575;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }

  .btn-group {
    display: flex;
    align-items: center;
    max-width: 300px;
    .btn-tab {
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      text-align: center;
      vertical-align: middle;
      border-radius: 0;
      color: #000;
      background-color: transparent;
      user-select: none;
      box-shadow: none;
      outline: none;
      -webkit-appearance: none;
      &:focus,
      &:active,
      &:hover {
        background-color: transparent;
        color: #000;
      }
    }
    .btn-tab-active {
      border-bottom: 4px solid #115fa7;
    }
  }

  .separate-pinvestment-business {
    height: 2px;
    background-color: #e5e5e5;
    border-radius: 2px;
    margin: 0.5rem 0;
  }

  .font-unit {
    color: #115fa7;
  }

  .disabled-click {
    color: #222222 !important;
    text-decoration: none !important;
  }
}

.detail {
  align-items: flex-start;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #115fa7;
    min-height: 120px;
    .business-label {
      color: #fff;
      font-size: 1rem;
    }
    .business-name {
      color: #fff;
      font-size: 1.25rem;
      min-height: 36px;
    }
  }
  .table {
    position: relative;
    font-size: 0.8rem;
    thead {
      th {
        color: #202020;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
        position: sticky;
        top: 0;
        background-color: #fff;
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.2);
        border-top: 1px solid #dadada;
        &:first-child {
          border-left: 1px solid #dadada;
        }
        &:not(:last-child) {
          border-right: 1px solid #dadada;
        }
      }
    }
    td {
      padding: 0.5rem;
      vertical-align: middle;
      text-align: center;
      border-top: 1px solid #dadada;
      &:first-child {
        border-left: 1px solid #dadada;
      }
      &:not(:last-child) {
        border-right: 1px solid #dadada;
      }
    }
    tr:last-child {
      border-bottom: 1px solid #dadada;
    }
    .preparing-item {
      &:not(:last-child) {
        border-bottom: #e9e8e8 solid 1px;
        padding-bottom: 0.25rem;
        margin-bottom: 0.25rem;
      }
      .preparing-item-total {
        width: 100%;
        justify-content: space-between;
        display: flex;
      }
      .preparing-item-detail {
        padding: 4px 4px 4px 8px;
        font-size: 11px;
        .item {
          margin: 0;
          display: flex;
          justify-content: space-between;

        }
      }
    }
  }
  .revenue-detail {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 1rem;
    .group {
      display: flex;
      flex-direction: column;
    }
    .label-group {
      display: flex;
      justify-content: space-between;
      color: #115fa7;
      font-weight: bold;
      margin: 0.25rem 0;
    }
    .list-label-data {
      padding: 0 1rem;
    }
    .label-data {
      display: flex;
      justify-content: space-between;
      color: #303030;
      margin-bottom: 0.2rem;
    }
    .line {
      border-bottom: solid #eee 2px;
    }
    .label-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .label-name-category {
      font-size: 0.9rem;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
    }
    .label-unit {
      font-weight: normal;
      margin-left: 1rem;
    }
  }

  .project-daterange {
    font-size: 1rem;
    color: #ffffff;
  }

  .table-remark {
    padding: 0rem 1rem;
    font-size: 0.8rem;
    color: #303030;
  }
}

.border-bottom-1 {
  border-bottom: 1px solid #818181;
}

.datepicker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .date-yearmonth {
    font-size: 1.2rem;
  }
  .btn-prev-next-month {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }
  .btn-datepicker {
    width: unset;
    background-color: #fff;
    color: #000;
    border: 1px solid rgba(122, 122, 122, 1);
    box-shadow: none;
    outline: none;
    &:focus,
    &:active,
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
}
